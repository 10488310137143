<template>
  <div>
    <div class="p-banner mb-40">
      <img v-if="$baseVal.isPc" alt="" class="p-banner-bg" src="../assets/img/aboutUs/banner.png">
      <img v-else alt="" style="object-position: unset" class="p-banner-bg" src="../assets/img/aboutUs/banner_wap.jpg">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">关于我们</div>
        <div class="p-banner-sub-title">关于我们</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title">我们是谁</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>重庆敏恒科技有限公司是一家为客户提供先进工艺技术和创新设备解决方案服务的科技型企业。服务领域涵盖化工、环保、能源、材料和制药等。
        公司秉承绿色化工理念，采用功能整合、连续流和过程强化等技术，推动生产设备微型化、生产过程智能化及资源利用最大化，旨在提升生产效率和产品质量，减少安全事故、降低劳动强度，为合作伙伴创造持久的竞争优势。
      </div>
      <div class="product-item-title">组织结构</div>
      <div class="preview-image mb-40"><img alt="" v-image-preview class="page-img " v-animate-on-intersect src="../assets/img/aboutUs/us1.png"></div>
      <div class="product-item-title">企业文化</div>
      <el-row :gutter="40" v-animate-on-intersect class="flex-warp mb-25" justify="space-between" type="flex">
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <div class="aboutUs-item">
            <div class="img-item-title">企业使命</div>
            <div class="grey-box page-text">开发先进工艺技术，创新生产设备，构建安全、清洁和智能的化工生产。</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <div class="aboutUs-item">
            <div class="img-item-title">企业愿景</div>
            <div class="grey-box page-text">成为行业领先的先进技术和创新设备解决方案服务供应商；
              <br>成为客户的首选合作伙伴；
              <br>成为员工成就事业的最佳平台。
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <div class="aboutUs-item">
            <div class="img-item-title">企业价值观</div>
            <div class="grey-box page-text">平等自主、信任担当、共创共赢。</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">创新一览</div>
      <div class="grey-box create-line-outer mb-40" v-animate-on-intersect>
        <div class="create-line">
          <div class="create-line-x"></div>
          <div class="create-line-item">
            <div class="create-line-item-cont">
              <div class="name">2021年</div>
              <div class="page-text f-bold">为碳酸亚乙烯酯设计的专用分子蒸馏器成功实现工业化应用</div>
            </div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-empty"></div>
          </div>
          <div class="create-line-item">
            <div class="create-line-empty"></div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-item-cont flex-col-end">
              <div class="name">2022年</div>
              <div class="page-text f-bold">固定床催化氧化制备环氧丁烯工艺技术开发成功</div>
            </div>
          </div>
          <div class="create-line-item">
            <div class="create-line-item-cont">
              <div class="name">2023年</div>
              <div class="page-text f-bold">完成ODA连续升华凝华工艺技术和成套装置开发</div>
            </div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-empty"></div>
          </div>
          <div class="create-line-item">
            <div class="create-line-empty"></div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-item-cont flex-col-end">
              <div class="name">2024年</div>
              <div class="page-text f-bold">实现固定床催化氧化合成硫酸乙烯酯</div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-item-title">荣誉与专利</div>
      <div class="btm-swiper"  @touchstart="stopAutoplay" @touchend="startAutoplay" @mouseenter="stopAutoplay" @mouseleave="startAutoplay">
        <div class="prev"  v-if="$baseVal.isPc" ></div>
        <div class="next"  v-if="$baseVal.isPc" ></div>
        <swiper ref="aboutUsSwiper" :options="swiperOption" class="preview-swiper">
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-l-1.png" alt="" style="background: #FFF9EB"></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-l-2.png" alt="" style="background: #FFF9EB"></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book1.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book2.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book3.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book4.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book5.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book6.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book7.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book8.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book9.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book10.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book11.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book12.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book13.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book14.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book15.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book16.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book17.png" alt=""></div></swiper-slide>


<!--          英文版本-->
<!--          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-3.png" alt=""></div></swiper-slide>-->
<!--          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-4.png" alt=""></div></swiper-slide>-->

        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import 'swiper/swiper-bundle.css';

export default {
  // name: "关于我们",
  data(){
    return{
      swiperOption:{
        slidesPerView:'auto',
        spaceBetween:this.$baseVal.isPc ? 30 : 10,
        speed:5000,
        autoplay:{
          delay: 30,//1秒切换一次
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        },
        freeMode: true,
        navigation:{
          nextEl:'.next',
          prevEl:'.prev'
        }
      }
    }
  },
  methods:{
    stopAutoplay() {
        this.$refs.aboutUsSwiper.swiper.autoplay.stop();
    },
    startAutoplay() {
        this.$refs.aboutUsSwiper.swiper.autoplay.start();
    }
  }
}
</script>

<style>
@import "../assets/css/product.css";
</style>
